<template>
  <Modal :wrapperClass="'disclaimer'">
    <div slot="body">
      {{activeGuide.description}}
    </div>
    <div slot="footer">
      <button type="button" class="submit"
              @click="hideDisclaimer">{{translations['ok_button']}}</button>
    </div>
  </Modal>
</template>

<script>
import Cookies from 'js-cookie';
import Modal from './Modal.vue';

export default {
  name: 'DisclaimerModal',
  components: { Modal },
  computed: {
    activeGuide() {
      return this.$store.getters.getActiveGuide;
    },
    translations() {
      return this.$store.getters.getTranslations;
    },
  },
  methods: {
    hideDisclaimer() {
      Cookies.set('disclaimerIsShown', 'true', { expires: 7 });
      this.$store.commit('setDisclaimerIsShown', true);
    },
  },
};
</script>

<style scoped lang="scss">
  .submit {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    color: #ffffff;
    background-color: $blueColor;
    border: none;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    transition: background-color .3s ease 0s;
    &:hover {
      background-color: darken($blueColor, 10%);
    }
  }
</style>
