<template>
    <div class="modal-mask">
      <div class="modal-wrapper" :class="wrapperClass">
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['wrapperClass'],
};
</script>

<style scoped lang="scss">
  @import "@/assets/styles/components/modal.scss";
</style>
